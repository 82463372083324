<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="name"
    :rules="rules"
    class="static-input-container"
    :class="styles"
    v-slot="{ errors, required }"
  >
    <div>
      <p>
        {{ label }}

        <span v-if="required">
          *
        </span>
      </p>
    </div>

    <div class="input-container">
      <input
        v-if="hasCardBrand"
        :type="inputType"
        @blur="handleBlur"
        :value="value"
        :placeholder="placeholder"
        v-model="innerValue"
        autocomplete="off"
        :maxlength="17"
        :disabled="disable"
        :class="{ 'error': !!errors[0], 'disable': disable }"
        :style="{ 'background': flagImage }"
      />

      <input
        v-else-if="mask == 'phone'"
        :type="inputType"
        @blur="handleBlur"
        :value="value"
        :placeholder="placeholder"
        v-mask="['(##) #####-####', '(##) ####-####']"
        v-model="innerValue"
        autocomplete="off"
        :class="{ 'error': !!errors[0], 'disable': disable }"
        :disabled="disable"
        :maxlength="maxLength"
      />

      <input
        v-else-if="!!mask"
        :type="inputType"
        @blur="handleBlur"
        :value="value"
        :placeholder="placeholder"
        v-model="innerValue"
        autocomplete="off"
        v-mask="mask"
        :disabled="disable"
        :maxlength="maxLength"
        :class="{ 'error': !!errors[0], 'disable': disable }"
      />

      <input
        v-else
        :type="inputType"
        @blur="handleBlur"
        :value="value"
        :placeholder="placeholder"
        v-model="innerValue"
        autocomplete="off"
        :disabled="disable"
        :maxlength="maxLength"
        :class="{ 'passwd': inputType == 'password', 'error': !!errors[0], 'disable': disable }"
      />

      <span v-if="!!errors[0]">
        {{ errors[0] }}
      </span>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import Validation from '@/services/validations';
  import MasterDefault from '@/assets/brands/master-default.svg';
  import Master from '@/assets/brands/master.svg';
  import Elo from '@/assets/brands/elo.svg';
  import Visa from '@/assets/brands/visa.svg';
  import Aura from '@/assets/brands/aura.png';
  import Jcb from '@/assets/brands/jcb.svg';
  import Amex from '@/assets/brands/_amex.svg';
  import Discover from '@/assets/brands/discover.svg';
  import Banese from '@/assets/brands/banese.svg';
  import Hiper from '@/assets/brands/hiper.svg';

  export default {
    name: 'InputField',

    props: {
      type: {
        type: String,
        default: "text",
        validator(value) {
          return [
            "url",
            "text",
            "password",
            "tel",
            "search",
            "number",
            "email",
          ].includes(value);
        },
      },

      vid: {
        type: String,
        default: undefined
      },

      placeholder: {
        type: String,
        required: true
      },

      errorApi: {
        type: Boolean,
        default: false
      },

      label: {
        type: String,
        default: ''
      },

      styles: {
        type: String,
        default: ''
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      value: {
        type: null,
        required: ''
      },

      maxLength: {
        type: Number,
        required: false
      },

      hasCardBrand: {
        type: Boolean,
        required: false
      },

      mask: {
        type: [Array, String],
        required: false
      },

      refName: {
        type: String,
        required: false
      },

      disable: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ValidationProvider,
    },

    data() {
      return {
        innerValue: null,
        visibility: false,
        hasError: false,
        inputType: 'text',
        name: null,

        path: MasterDefault,
        master: Master,
        visa: Visa,
        elo: Elo,
        aura: Aura,
        jcb: Jcb,
        amex: Amex,
        discover: Discover,
        banese: Banese,
        hiper: Hiper,
        masterDefault: MasterDefault,
      };
    },

    created() {
      this.inputType = this.type;

      if (this.value) {
        this.innerValue = this.value;
      }
    },

    mounted() {
      if (this.refName) {
        this.name = this.refName;
      } else {
        this.name = this.label.toLowerCase();
      }
    },

    computed: {
      hasValue() {
        return !!this.innerValue;
      },

      flagImage() {
        return 'url(' + `${this.path}` + ') #FFFFFF no-repeat 95%';
      }
    },

    watch: {
      innerValue(value) {
        if (this.hasCardBrand && value) {
          this.innerValue = value.replace(/\s+/g, '');
        }

        this.$emit('input', value);

        this.path = this.identifyFlag(Validation.isValidCard(value));
      },

      value(val) {
        if (val !== this.innerValue) {
          this.innerValue = val;
        }
      }
    },

    methods: {
      hasInnerValue() {
        if (this.innerValue == null || this.innerValue == '') return false;

        else return true;
      },

      toggleVisibility() {
        if (this.visibility) {
          this.visibility = false;

          this.inputType = 'password';
        } else {
          this.visibility = true;

          this.inputType = 'text';
        }
      },

      identifyFlag(flag) {
        switch(flag) {
          case 'visa':
            return this.visa;
          case 'master':
            return this.master;
          case 'elo':
            return this.elo;
          case 'aura':
            return this.aura;
          case 'jcb':
            return this.jcb;
          case 'amex':
            return this.amex;
          case 'discover':
            return this.discover;
          case 'banese':
            return this.banese;
          case 'hiper':
            return this.hiper;
          default:
            return this.masterDefault;
        }
      },

      handleBlur() {
        this.$emit('blur', true);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .static-input-container {
    width: 100%;

    &.large {
      max-width: 20rem;
    }

    &.medium {
      max-width: 15rem;
    }

    &.small {
      max-width: 11rem;
    }

    p {
      margin-bottom: 0.5rem;
      font-size: 14px;
    }

    span {
      color: #b93a5b;
      font-size: 12px;
    }

    .input-container {
      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 48px;
        border: 1px solid #DADADA;
        background: #FFFFFF;
        border-radius: 6px;
        padding: 1rem;
        font-size: 14px;

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &.disable {
          opacity: 0.6;
          cursor: not-allowed
        }

        &.passwd {
          padding: 0.5rem 3rem 0.5rem 1rem;
        }

        &.error {
          border: 2px solid #b93a5b;
        }
      }

      .icon-container {
        color: #8E8E8E;;
        float: right;
        position: relative;
        bottom: 35px;
        right: 15px;
        height: 0;

        i {
          font-size: 1.5rem;
        }
      }

      span {
        color: #b93a5b;
        margin-top: 0.2rem;
        display: flex;
      }
    }
  }

  @media (max-width: 1350px) {
    .static-input-container {
      &.large {
        max-width: 18rem;
      }

      &.medium {
        max-width: 13rem;
      }
    }
  }

  @media (max-width: 1280px) {
    .static-input-container {
      &.large {
        max-width: 100%;
      }

      &.medium {
        max-width: 100%;
      }

      &.small {
        max-width: 100%;
      }
    }
  }

  @media (max-width: 520px) {
    .static-input-container {
      span {
        font-size: 10px;
      }

      p {
        font-size: 12px;
      }

      .input-container {
        input {
          font-size: 12px;
        }
      }
    }
  }
</style>